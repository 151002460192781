.scroll-top-button {
  position: fixed;
  bottom: 3.2rem;
  right: 1.2rem;
  padding: 10px;
  border-radius: 50%;
  color: rgb(255, 255, 255);
  background: pink;
  cursor: pointer;
  opacity: 0.8;
  z-index: 999;

  &.material-icons {
    font-size: 2rem;
  }
}
