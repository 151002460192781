@mixin button(
  $radius: 3px,
  $bg-image: linear-gradient(0, #5d5dff, #5d5dff),
  $hover-bg-image: linear-gradient(0, #005089e3, #5d5dff)
) {
  padding: 0.6rem 1rem;
  border-radius: $radius;
  color: #fff;
  background-image: $bg-image;
  white-space: nowrap;
  letter-spacing: 2px;

  &:hover {
    background-image: $hover-bg-image;
    color: #fff;
  }
}
