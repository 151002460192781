.user-header {
  &__wrapper {
    max-width: 50%;
    margin: auto;
    display: flex;
    align-items: flex-end;
    padding: 20px 0;

    > *:not(:nth-last-child(1)) {
      margin-right: 1rem;
    }
  }

  &__logout {
    margin-left: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    flex: 1;
    min-width: 75px;

    > *:not(:nth-last-child(1)) {
      margin-bottom: 10px;
    }

    &:hover span,
    &:hover p {
      text-shadow: 0 0 black;
    }

    span {
      font-size: 25px;
    }
    p {
      font-size: 11px;
    }
  }

  &__avatar {
    width: 77px;
    max-width: 77px;
    min-width: 77px;
    flex: 1;
    height: 77px;
    cursor: pointer;
    align-self: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrapper--user {
    max-width: 60%;

    figure {
      border-radius: 50%;
    }
  }

  &__name {
    flex: 4;

    > * {
      font-size: 14px;
    }
    &--eng {
      font-size: 25px;
      margin-bottom: 0.6rem;
      overflow-wrap: anywhere;
      word-break: break-all;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .user-header {
    &__wrapper {
      max-width: 60%;
    }
  }
}
@media only screen and (max-width: 1400px) {
  .user-header {
    &__wrapper {
      max-width: 70%;
    }
  }
}
@media only screen and (max-width: 900px) {
  .user-header {
    &__wrapper {
      max-width: 80%;
    }

    &__name--eng {
      font-size: 23px;
    }
  }
}

@media only screen and (max-width: 700px) {
  .user-header {
    &__wrapper {
      max-width: 90%;

      > *:not(:nth-last-child(1)) {
        margin-right: 5px;
      }
    }
  }
}

@media only screen and (max-width: 370px) {
  .user-header {
    &__name--eng {
      font-size: 20px;
    }
  }
}
