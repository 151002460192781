.staff-view {
  overflow: auto;

  &__table {
    overflow-x: auto;
    table > tbody > tr {
      td.buttons {
        white-space: nowrap;
      }
    }

    table > thead > tr {
      th {
        &.col-username {
          width: 30%;
          white-space: nowrap;
        }
        &.col-time {
          width: 10%;
          white-space: nowrap;
        }
        &.col-service {
          width: 40%;
          white-space: nowrap;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .staff-view {
    &__table {
      table > thead > tr {
        th {
          &.col-username {
            width: 20%;
            white-space: nowrap;
          }
          &.col-time {
            width: 10%;
            white-space: nowrap;
          }
          &.col-service {
            width: 50%;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .staff-view__table {
    table {
      * {
        font-size: 14px;
      }
    }
  }
}

@media only screen and (max-width: 570px) {
  .staff-view__table {
    table {
      width: 600px;
    }
  }
}
