@keyframes showView {
  0% {
    margin-left: -10px;
    visibility: visible;
    opacity: 0.2;
  }
  20% {
    margin-left: -8px;
    visibility: visible;
    opacity: 0.4;
  }
  40% {
    margin-left: -6px;
    visibility: visible;
    opacity: 0.6;
  }
  60% {
    margin-left: -4px;
    visibility: visible;
    opacity: 0.8;
  }
  80% {
    margin-left: -2px;
    visibility: visible;
    opacity: 0.8;
  }
  100% {
    margin-left: 0;
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    visibility: visible;
    opacity: 0.2;
  }
  20% {
    visibility: visible;
    opacity: 0.4;
  }
  40% {
    visibility: visible;
    opacity: 0.6;
  }
  60% {
    visibility: visible;
    opacity: 0.8;
  }
  80% {
    visibility: visible;
    opacity: 0.8;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
