.common-view {
  max-width: 600px;
  margin: auto;
  $self: &;

  &__input-with-count {
    position: relative;

    #{ $self }__count {
      position: absolute;
      font-size: 12px;
      top: -8px;
      right: 0;
    }
  }

  &__buttons {
    text-align: center;
    margin-top: 30px;
  }

  &__copy-btn {
    position: relative;

    svg {
      position: absolute;
      left: 0;
      top: 0px;
      visibility: hidden;
      opacity: 0;
      fill: green;
    }

    &--animate {
      svg {
        animation: copy-done 0.2s forwards;
      }
      span {
        opacity: 0;

        &:hover {
          opacity: 0 !important;
        }
      }
    }
  }

  .booking-form__row {
    margin-bottom: 1rem;
  }

  .booking-form__label {
    min-width: 150px;
  }

  .booking-form__control {
    p {
      font-size: 14px;
    }

    &--link {
      display: flex;
      align-items: center;

      a {
        text-decoration: underline;
        color: #6262e6;
        margin-right: 10px;
      }

      span {
        font-size: 1.4rem;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

@keyframes copy-done {
  0% {
    opacity: 0;
    visibility: visible;
  }
  25% {
    opacity: 0.25;
    top: -2px;
  }
  50% {
    opacity: 0.5;
    top: -3px;
  }
  75% {
    opacity: 0.75;
    top: -4px;
  }
  100% {
    opacity: 1;
    visibility: visible;
    top: -5px;
  }
}
