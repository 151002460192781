.guide-modal {
  .dialog {
    width: 400px;
    min-width: 400px;
    padding: 2rem;
    margin-top: 120px;

    @media only screen and (max-width: 500px) {
      width: 80%;
      min-width: unset;
    }

    > header {
      display: none;
    }
  }
  pre {
    overflow: hidden;
    text-align: center;
    line-height: 1.5;
    font-size: clamp(14px, 0.9vw, 1.2vw);
  }

  &__btn-area {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
