.user-booking-register {
  height: unset;
  padding-bottom: 4rem;

  &__wrapper {
    width: 500px;
  }

  p.error-text {
    font-size: 12px;
  }

  &__password-field {
    position: relative;

    input {
      padding-right: 30px;
    }

    svg {
      position: absolute;
      top: 50%;
      right: 6px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  &__confirm-box {
    display: flex;
    align-items: center;

    input[type="checkbox"] {
      margin-right: 5px;
      cursor: pointer;
      width: 16px;
      height: 16px;
    }

    a {
      border: 0;
      background-color: transparent;
      color: #00a100;
      border-bottom: 1px solid #00a100;
      padding: 0;
      padding-bottom: 1px;

      &:hover {
        color: #008600;
      }
    }
  }

  &__txt-hint {
    font-size: 12px;
    line-height: 1.2;
  }

  // .login-btn {
  //   button {
  //     padding: 10px 25px;

  //     &:not([disabled]):hover {
  //       background-color: #8686ff;
  //     }
  //   }
  // }

  .booking-form {
    &__row {
      margin-bottom: 3rem !important;
    }

    &__control {
      width: 100%;

      input {
        width: 100%;
      }

      @media only screen and (max-width: 500px) {
        .MuiFormControl-root.MuiTextField-root {
          width: 100%;
        }
      }
    }

    &__label {
      position: relative;

      label::after {
        position: absolute;
        top: -5px;
      }
    }

    &__row .child {
      margin-top: 2rem;
      margin-left: 1rem;
    }
  }

  @media only screen and (max-width: 550px) {
    &__wrapper {
      width: 90%;
    }
  }
}
