$white: #fff;
$bunting: #283046;
$brown: brown;

.base-content {
  height: 100%;
  margin-left: 260px;
  background-color: #f5f5f5;

  .wrapper {
    display: flex;
    flex-direction: column;
    visibility: hidden;
    opacity: 0;
    margin-left: -10px;
    animation: showView 0.2s ease-out forwards;
    background-color: #f5f5f5;
    height: 100%;
    overflow: auto;
  }

  .wrapper > header {
    position: relative;
    background-color: #f5f5f5;
    color: $bunting;
    letter-spacing: 1px;
    padding: 0;
    height: 50px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin-bottom: 2rem;

    h2 {
      width: fit-content;
      margin: auto;
      font-size: 2rem;
      text-shadow: 0 0 black;
      letter-spacing: 5px;
      position: relative;
    }
  }

  &__main {
    border: 0;

    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 1.2rem 1rem;
  }
}
@media only screen and (max-width: 1000px) {
  .base-content {
    margin-left: 220px;
  }
}

@media only screen and (max-width: 900px) {
  .base-content {
    margin-left: 0;
  }
}

@media only screen and (max-width: 500px) {
  .base-content .wrapper > header h2 {
    font-size: 25px;
  }
}
