.discount {
  &__table {
    table > thead > tr {
      th.col-username {
        width: 20%;
      }
      th.col-service {
        width: 20%;
      }
      th.col-status {
        width: 40%;
      }
      th.col-activity {
        width: 20%;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .discount__table {
    table {
      * {
        font-size: 14px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .discount__table {
    overflow-x: auto;

    table {
      width: 560px;
    }
  }
}
