$white: #fff;
$bunting: #283046;
$gainsboro: #dcdcdc;
$electric-blue: #78ddff;
$slate-blue-50: #615ac880;
$white-smoke: #f5f5f5;
$charcoal: #4b4b4b;
$salmon: #fa8072;
$brown: #b23628;
$mint-julep: #d6dfa7;

#create-booking-dialog {
  .dialog {
    min-width: 700px;
    width: 700px;
  }

  .create-booking-btn {
    padding: 10px 30px;
  }
}

@media only screen and (max-width: 800px) {
  #create-booking-dialog {
    .dialog {
      min-width: 90%;
      width: 90%;

      header {
        margin-bottom: 2.5rem;
        font-size: 2rem;
      }
    }
  }
}

#booking {
  height: 100%;
}

.rbc-toolbar .rbc-toolbar-label {
  font-size: 1.4rem;
  color: #5a5a5a;
}

.rbc-allday-cell {
  display: none;
}
.rbc-today {
  background-color: #fffadf;
}
.rbc-event {
  background-color: unset;
  border-radius: 3px;
  border: 0 !important;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px 1px;
}

.rbc-current-time-indicator {
  display: none;
}

.rbc-m-custom-event {
  &__content {
    padding: 8px;

    li {
      // font-size: 0.95rem;
      font-size: 15px;
      margin-bottom: 5px;
      line-height: 1.4;
      color: #fff;
    }
  }
}

.rbc-timeslot-group {
  min-height: 120px;

  .rbc-time-slot:nth-of-type(1) {
    display: flex;
    align-items: center;
    flex: 1;
  }
  .rbc-time-slot:nth-of-type(2) {
    flex: 0;
  }
}

.rbc-event-label {
  padding: 7px;
  background: #3788d8;
  color: #fff;
  position: absolute;
  text-shadow: 0 0 #b23628;
  height: 27px;
  width: 100% !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.rbc-event-content {
  background-color: #3788d8;
  color: #000;
  margin-top: 27px;
}

.rbc-time-view .rbc-row {
  min-height: 40px;
}

.rbc-time-view {
  background: #fbfbfb;
  border: 0;
}

.rbc-time-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.rbc-time-gutter.rbc-time-column {
  position: -webkit-sticky;
  z-index: 999;
}

.rbc-time-content {
  // border-bottom-left-radius: 10px;
  // border-bottom-right-radius: 10px;

  // &::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  //   border-radius: 10px;
  //   background-color: $white-smoke;
  // }

  // &::-webkit-scrollbar {
  //   width: 17px;
  //   background-color: $white-smoke;
  // }

  // &::-webkit-scrollbar-thumb {
  //   border-radius: 10px;
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  //   background-color: #c0c0c0;
  // }
}

.rbc-overflowing .rbc-header {
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  font-size: 1rem;
  color: #3e3e3e;
  text-shadow: 0 0 black;
}

.rbc-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  padding: 10px 10px;
}

.rbc-addons-dnd
  .rbc-addons-dnd-resize-ns-anchor
  .rbc-addons-dnd-resize-ns-icon {
  width: 1rem;
}

.rbc-toolbar {
  padding: 0.8rem 0;
}

.rbc-toolbar.custom {
  margin-bottom: 0;
  position: relative;
  background: #f5f5f5;
  color: $bunting;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 0;
  text-shadow: 0 0 black;
  height: 60px;
  border: 1px solid #ddd;
  border-bottom-width: 2px;
  font-size: 1.2rem;

  .navigate-wrapper {
    display: inline-flex;
    font-size: 1.5rem;
    font-weight: normal;
    color: #3e3e3e;
  }

  @media only screen and(max-width: 400px) {
    height: 80px;
  }
}

.rbc-navigate-wrapper {
  display: flex;
  align-items: center;

  i {
    font-size: 1.2rem;
  }

  .today-btn {
    margin-left: 6px;
    cursor: pointer;
    user-select: none;
    font-size: 1.2rem;
    color: #ff5574;

    &:hover {
      // color: #3e3e3e;
    }
  }
}

.booking {
  &__warning {
    position: fixed;
    top: 0;
    z-index: 999999;
    background: rgba(0, 0, 0, 0.5);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__warning-content {
    width: 70%;
    padding: 1.5rem 2rem;
    background: #fff;
    border-radius: 5px;

    p {
      font-size: 12px;
      text-align: center;
      line-height: 1.5;
      margin-bottom: 1rem;
      color: #555;
    }
  }

  &__warning-button {
    text-align: center;

    button {
      span {
        font-size: 12px;
      }
    }
  }

  &__big-calendar {
    height: 100%;
    // padding: 1.1rem 1.5rem;
    background: #f5f5f5;
    margin-left: 260px;
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
    position: relative;
  }

  &__no-staff-announce {
    text-align: center;
    padding-top: 6rem;
    font-size: 3rem;
    @media only screen and (max-width: 700px) {
      font-size: 20px;
    }
  }

  &__not-woring-day-announce {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);

    h2 {
      font-size: 2.3rem;
    }
  }
}

.rbc-toolbar-custom__bell {
  position: absolute;
  right: 30px;
}

.rbc-calendar {
  visibility: hidden;
  opacity: 0;
  margin-left: -10px;
  animation: showView 0.1s ease-out forwards;
}

.delete-booking-btn {
  position: absolute;
  right: 2px;
  top: 2px;
  font-size: 1.2rem !important;
  color: #000;
  cursor: pointer;
  padding: 2px;
  transition: all 0.2s ease;
  border-radius: 50%;

  &:hover {
    background: #9b9b9b;
  }
}

.plus-icon {
  &:before {
    content: "+";
    font-size: 2rem;
    position: absolute;
    // font-weight: bold;
    left: 50%;
    color: #4b4b4b;
    text-shadow: 0 0 #4b4b4b;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    line-height: 1;
  }
}

.minus-icon {
  &:before {
    content: "-";
    font-size: 3rem;
    position: absolute;
    color: #4b4b4b;
    text-shadow: 0 0 #4b4b4b;
    left: 50%;
    top: -3px;
    transform: translateX(-50%);
    cursor: pointer;
    line-height: 1;
  }
}

.dialog-overlay.custom-delete-dialog {
  > .dialog {
    margin-top: 8rem;
  }
}

.booking-dialog {
  &__note {
    margin-top: 10px;
    font-size: 12px;
    color: #7c7c7c;
  }

  .booking-form__label--create-booking-staff {
    align-items: center;
  }

  .dialog {
    @media only screen and (max-width: 1300px) {
      min-width: 80%;
      width: 80%;
    }
  }
}
.hide-calendar {
  visibility: hidden;
  opacity: 0;
}
.show-calendar {
  visibility: visible;
  opacity: 1;
}

.rbc-btn-group.custom {
  svg {
    fill: #ff5574;
    cursor: pointer;
    width: 20px;
  }
}

@media only screen and(max-width: 1000px) {
  .booking__big-calendar {
    margin-left: 220px;
  }
}

@media only screen and(max-width: 900px) {
  .booking__big-calendar {
    margin-left: 0;
  }
}

@media only screen and(max-width: 400px) {
  .rbc-toolbar.custom .navigate-wrapper {
    flex-direction: column;
    align-items: center;

    .rbc-toolbar-label {
      margin-bottom: 10px;
    }

    * {
      font-size: 18px;
    }
  }
}
