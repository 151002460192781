$white: #fff;
$gainsboro: #e6e6e6;
$night-rider: #333;
$dark-gray: #b3b3b3;

#detail-booking-dialog {
  @media only screen and (max-width: 600px) {
    header {
      font-size: 2rem;
      margin-bottom: 3rem;
    }
  }

  @media only screen and (max-width: 500px) {
    header {
      font-size: 1.8rem;
      margin-bottom: 3rem;
    }
    .dialog {
      padding: 1rem 1.5rem;
    }
    .booking-form__btns {
      > :not(:nth-last-child(1)) {
        margin-right: 10px;
      }
    }
  }
}

.detail-modal {
  &__back-btn {
    position: absolute;
    left: 15px;
    top: 20px;
    font-size: 1.6rem;
    cursor: pointer;
    display: block;
    visibility: hidden;
    animation: back-btn-appear 0.1s forwards linear;
    transition: hover 0.2s ease;

    &:hover {
      color: grey;
    }
  }
}

@keyframes edit-btn-appear {
  0% {
    visibility: visible;
    opacity: 0.5;
  }

  50% {
    right: 12px;
    visibility: visible;
    opacity: 0.7;
  }

  100% {
    right: 15px;
    visibility: visible;
    opacity: 1;
  }
}

@keyframes back-btn-appear {
  0% {
    visibility: visible;
    opacity: 0.5;
  }

  50% {
    left: 20px;
    visibility: visible;
    opacity: 0.7;
  }

  100% {
    left: 25px;
    visibility: visible;
    opacity: 1;
  }
}

.MuiInputBase-input.Mui-disabled {
  color: #000;
}

.detail-button.custom {
  background-image: linear-gradient(0, green, green);
}
