.search-button {
  @include button(
    $radius: 3px,
    $bg-image: linear-gradient(0, #1996d2, #1996d2),
    $hover-bg-image: linear-gradient(0, #1b8bc1, #1996d2)
  );
}

.create-button {
  @include button(
    $radius: 3px,
    $bg-image: linear-gradient(0, #409eff, #409eff),
    $hover-bg-image: linear-gradient(0, #1689ff, #409eff)
  );

  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  &[disabled] {
    pointer-events: none;
    cursor: not-allowed;
  }
}

.add-button {
  @include button(
    $radius: 3px,
    $bg-image: linear-gradient(0, #1996d2, #1996d2),
    $hover-bg-image: linear-gradient(0, #1b8bc1, #1996d2)
  );

  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  &[disabled] {
    pointer-events: none;
    cursor: not-allowed;
  }
}

.edit-button {
  background-color: #4894e5;
  padding: 0.6rem 1rem;
  border-radius: 3px;
  color: #fff;
  white-space: nowrap;
  letter-spacing: 2px;
  width: fit-content;
  cursor: pointer;

  &[disabled] {
    pointer-events: none;
    cursor: not-allowed;
  }
}

.detail-button {
  @include button(
    $radius: 3px,
    $bg-image: linear-gradient(0, #009d95, #009d95),
    $hover-bg-image: linear-gradient(0, #008e87, #009d95)
  );
  letter-spacing: 2px;
  text-indent: 2px;
  transition: all 1s ease;
}

.delete-button {
  @include button(
    $radius: 3px,
    $bg-image: linear-gradient(0, #ff7e7e, #ff7e7e),
    $hover-bg-image: linear-gradient(0, #ff5c5c, #ff7e7e)
  );
  &[disabled] {
    pointer-events: none;
    cursor: not-allowed;
  }
}

.cancel-button {
  @include button(
    $radius: 3px,
    $bg-image: linear-gradient(0, #fff, #fff),
    $hover-bg-image: linear-gradient(0, #efefef, #fff)
  );
  border: 1px solid #dfdfdf;
  color: #818181;
  // background: #fff;

  &:hover {
    color: #818181;
  }

  &[disabled] {
    pointer-events: none;
    cursor: not-allowed;
  }
}
.custom-scrollbar-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.custom-scrollbar-1::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.custom-scrollbar-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #5c5f65;
}

.error-text {
  color: red;
  margin-top: 5px;
  margin-left: 5px;
  font-size: 0.9rem;
}

.booking-form {
  $self: &;

  &__row {
    display: flex;
    align-items: baseline;
    gap: 15px;

    &.required {
      .booking-form__label {
        label:after {
          content: "*";
          margin-left: 3px;
          color: #ff2f00;
          font-size: 22px;
        }
      }
    }

    @media only screen and (max-width: 800px) {
      flex-direction: column;

      #{ $self }__label {
        margin-bottom: 10px;
      }

      .dialog {
        min-width: 90%;
        width: 90%;
      }

      * {
        font-size: 14px;
      }
    }
  }

  &__label {
    font-weight: normal;
    margin-left: -5px;
    flex: 1;
    margin-top: -18px;
    color: #4b4b4b;
    text-shadow: 0 0 #4b4b4b;
    display: flex;
    min-width: 200px;
    font-size: 1rem;
    align-items: flex-end;

    label {
      margin-bottom: 0;
    }

    i {
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__control {
    flex: 3;

    input,
    textarea {
      font-size: 15px;
    }

    select {
      font-size: 15px;
    }

    @media only screen and (max-width: 800px) {
      width: 100%;
    }
  }

  &__row:not(:nth-last-of-type(1)) {
    @media only screen and (max-width: 800px) {
      margin-bottom: 2.5rem !important;
    }
  }

  &__btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;

    & > :not(:nth-last-child(1)) {
      margin-right: 20px;
    }
  }
}

.form-switch {
  padding-left: 0;

  .form-check-input {
    margin-left: 0;
    margin-top: 0;
    cursor: pointer;
  }
}

.no-record-announce {
  text-align: center;
  padding-top: 6rem;
  font-size: 3rem;

  &.setting {
    font-size: 2rem;
  }
}

.auto-hide {
  animation: cssAnimation 0s ease-in 5s forwards;
  animation-fill-mode: forwards;
}

.fade-out {
  opacity: 0;
  visibility: hidden;
  animation: fadeOut 0.5s ease-out forwards;
}

@keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}

.js-for-copy-element {
  position: fixed;
  width: 10px;
  left: -20px;
  bottom: 0;
}
