.user-home {
  padding: 56px 0;
  background-color: #f7f8f8;

  &__wrapper {
    max-width: 50%;
    margin: auto;

    .common-return-top-button {
      margin-bottom: 1rem;
      font-weight: 500;
    }
  }

  .hide-required {
    &:after {
      content: none !important;
    }
  }

  * {
    font-size: 14px;
  }

  .cart-wrapper {
    background-color: #fff;

    @media only screen and (max-width: 750px) {
      max-width: 300px;
      margin: auto;
    }
  }

  &__cart {
    background: #f7f8f8;

    @media only screen and (max-width: 750px) {
      padding-bottom: 1rem;
    }
  }

  &__confirm-btn {
    width: 100%;
    display: block;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    border: 1px solid #b5b5b6;
    background: #fc5461;
    margin-top: 0.5rem;
    color: #fff;
    border-radius: 2px;

    &:disabled {
      background: #fff;
      color: #9fa0a0;
    }

    @media only screen and (max-width: 750px) {
      max-width: 300px;
      margin: auto;
      margin-top: 1rem;
    }
  }

  &__cart-items {
    height: 200px;
    border: 1px solid #b5b5b6;
  }

  &__cart-money {
    display: flex;
    padding: 15px;
    border: 1px solid #b5b5b6;
    // border-top: 0;

    span {
      color: #3e3a39;
      font-size: 0.9rem;
      font-weight: bold;
    }

    span:nth-child(2) {
      margin-left: auto;
      color: #fd666c;
    }
  }

  &__text-hint {
    margin-bottom: 20px;
    margin-top: -30px;
    line-height: 1.2;
  }

  &__ttl {
    color: #fc3c60;
    font-weight: bold;
    margin-bottom: 41px;

    @media only screen and (max-width: 750px) {
      text-align: center;
    }
  }

  &__ctn-wrapper {
    display: flex;

    > *:not(:nth-last-child(1)) {
      margin-right: 20px;
    }

    @media only screen and (max-width: 750px) {
      flex-direction: column;

      > *:not(:nth-last-child(1)) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
    ul {
      flex: 3;
      // margin-bottom: 20px;
    }

    div {
      flex: 1;
    }
  }

  .options {
    &__total {
      text-align: right;
      margin-top: 10px;
    }
    &__chosen-menu {
      display: flex;
      align-items: center;
      padding-bottom: 8px;
      border-bottom: 1px solid #dcdddd;

      > *:not(:nth-last-child(1)) {
        margin-right: 20px;
      }

      .name {
        flex: 15;
        font-weight: 500;
        line-height: 1.3;
      }

      .close-btn {
        font-size: 12px;
        border-radius: 50%;
        background: #f7f7f7;
        height: 15px;
        width: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000;
        cursor: pointer;
      }

      .price {
        flex: 1;
        min-width: 70px;
        text-align: right;
      }

      .time {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        white-space: nowrap;

        span {
          font-size: 12px;
        }

        > *:not(:nth-last-child(1)) {
          margin-right: 5px;
        }
      }
    }

    &__item {
      padding: 1rem 1.5rem;
      padding-bottom: 2.5rem;
      background: #fff;
      position: relative;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    &__description {
      color: #727171;

      .staff-name {
        margin-left: 2rem;
        margin-top: 10px;
        max-width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__label {
      position: relative;
      font-size: 16px;

      &:after {
        content: "必要";
        color: #fc3c60;
        right: -55px;
        top: 2px;
        position: absolute;
        font-size: 14px;
      }
    }

    &__label.last-one:after {
      right: -95px;
    }

    &__chose-btn {
      position: absolute;
      right: 18px;
      top: 12px;
      display: block;
      font-size: 12px;
      border: 1px solid #fc3c60;
      color: #fc3c60;
      padding: 6px 12px;
      border-radius: 30px;
      cursor: pointer;
      font-weight: bold;
    }

    &__item:not(:nth-last-child(1)) {
      margin-bottom: 1.8rem;
    }
    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      > *:not(:nth-last-child(1)) {
        margin-right: 10px;
      }

      p {
        font-weight: bold;
      }

      figure {
        width: 30px;
        height: 30px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .confirm-booking-dialog {
    .dialog {
      header {
        font-size: 20px !important;
        font-weight: 500;
      }
    }
    &__hint {
      font-size: 12px;
      text-align: center;
      margin-bottom: 28px;
    }

    &__result-row {
      display: flex;
      margin-bottom: 25px;
      align-items: flex-start;

      > *:not(:nth-last-child(1)) {
        margin-right: 20px;
      }

      &--phone {
        input {
          width: 100%;
          padding: 2px 6px;
          font-size: 13px;
          line-height: 1.5;
        }
      }

      &--note {
        textarea {
          width: 100%;
          resize: none;
          padding: 6px;
          font-size: 13px;
          line-height: 1.5;
        }
      }

      &.required {
        .confirm-booking-dialog__result-label {
          span {
            position: relative;
          }

          span:after {
            content: "*";
            position: absolute;
            color: #ff2f00;
            font-size: 22px;
            top: -6px;
          }
        }
      }
    }

    &__result-label {
      display: flex;
      min-width: 140px;
      flex: 1;
      align-items: center;
      font-weight: 500;

      svg,
      i {
        min-width: 20px;
      }

      i {
        font-size: 18px;
        text-align: center;
      }

      .estimate-amount {
        margin-left: 1.5rem;
      }

      > *:not(:nth-last-child(1)) {
        margin-right: 10px;
      }
    }

    &__confirm-btn {
      button {
        border: 0;
        width: 242px;
        padding: 11px 0;
        background-color: #fc3c60;
        border-radius: 2px;
        color: #fff;
        display: block;
        margin: auto;
        outline: none;

        @media only screen and(max-width: 320px) {
          width: 100%;
        }
      }
    }

    &__result-content {
      flex: 3;

      ul > li {
        margin-bottom: 6px;
        line-height: 1.5;
        word-break: break-word;
        white-space: break-spaces;
      }
    }

    .dialog {
      max-width: 550px;
      min-width: 550px;
      width: 550px;
      padding: 30px 98px;
      margin-top: 12%;

      header {
        font-size: 16px;
        margin-bottom: 24px;
      }
    }
  }
}
.remove-confirm-modal {
  .dialog {
    padding: 2rem 1rem;
    margin: auto;
  }
  .dialog header {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 1.2rem;
  }
  .dialog {
    max-width: 500px;
    min-width: 500px;
    width: 500px;
  }
  &__button {
    border: 0;
    display: block;
    width: 60%;
    padding: 12px 0;
    background-color: #fff;
    margin: auto;
    margin-bottom: 10px;
    border-radius: 3px;
    font-size: 12px;

    &--remove {
      background-color: #6a76d0;
      color: #fff;
    }

    &--no-need-confirm {
      border: 1px solid #6a76d0;
      color: #6a76d0;

      &:hover {
        background-color: #6a76d0;
        color: #fff;
      }
    }

    &--close {
      border: 1px solid #bbb;
    }
  }
}

#login-dialog {
  align-items: center;
  // background-color: unset;

  .register-btn {
    display: block;
    color: #1cb6b8;
    text-decoration: underline;

    &:hover {
      color: #05a4a7;
    }
  }

  .login-btn {
    text-align: center;

    button {
      border: 0;
      outline: none;
      width: 260px;
      padding: 12px;
      border-radius: 2px;
      background: #fc3c60;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;

      &:disabled {
        background: #c1c1c1;
        color: #666666;
        cursor: not-allowed;
      }
    }
  }

  .dialog {
    background-color: #fff;
    width: 500px;
    min-width: 500px;
    padding: 40px 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  header {
    font-size: 16px;
    font-weight: bold;
  }

  .dialog-content {
    max-width: 300px;
    margin: auto;
  }

  .booking-form {
    &__label {
      margin-bottom: 10px;
    }

    &__row {
      flex-direction: column;
      padding-bottom: 1rem;
    }

    &__control {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .user-home {
    &__wrapper {
      max-width: 60%;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .user-home {
    &__wrapper {
      max-width: 70%;
    }
  }
}
@media only screen and (max-width: 900px) {
  .user-home {
    &__wrapper {
      max-width: 80%;
    }
  }
}

@media only screen and (max-width: 800px) {
  #login-dialog {
    .dialog {
      width: 80%;
      min-width: 80%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .user-home .options__chosen-menu {
    > *:not(:nth-last-child(1)) {
      margin-right: 5px;
    }

    .price {
      min-width: 50px;
    }
  }

  .user-home {
    padding: 40px 0;

    .confirm-booking-dialog {
      &__result-row {
        * {
          font-size: 12px;
        }

        > :not(:nth-last-child(1)) {
          margin-right: 0;
        }
      }

      &__result-label {
        min-width: 100px;
      }

      .dialog {
        width: 90%;
        max-width: 90%;
        min-width: 90%;
        margin: auto;
        padding: 20px 5vw;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .options__chose-btn {
    bottom: 6px;
    top: unset !important;
  }
  .user-home .options__chosen-menu {
    * {
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 450px) {
  #login-dialog {
    .dialog {
      width: 95%;
      min-width: 95%;

      .close-button {
        top: 7px;
        right: 7px;
        font-size: 1.8rem;
      }

      .dialog-content {
        max-width: 80%;

        .login-btn {
          button {
            width: 100%;
          }
        }
      }
    }
  }
}
