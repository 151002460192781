.staff-delete-dialog {
  .dialog {
    width: 300px;
    min-width: 300px;
    margin-top: 9rem;
  }

  .booking-form__btns {
    margin: auto;
    margin-top: 1rem;
    justify-content: center;

    button:first-child {
      margin-right: 10px !important;
    }
  }
}
