.user-booking-login {
  height: 100%;
  padding-top: 4rem;
  background: #f8f8ff;

  * {
    font-size: 14px;
  }

  &__wrapper {
    width: fit-content;
    margin: auto;
    padding: 30px 30px;
    border-radius: 5px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;

    h2 {
      margin-bottom: 3rem;
      text-align: center;
      font-size: 1.5rem;
      text-shadow: 0 0 black;
    }
  }

  .login-btn {
    > button {
      border: 0;
      padding: 0.5rem;
      border-radius: 2px;
      background-color: #6f6fff;
      color: #fff;
      font-size: 14px;
      display: block;
      margin: auto;

      &:disabled {
        opacity: 0.8;
      }
    }
  }

  .booking-form {
    &__row {
      flex-direction: column;
      margin-bottom: 2rem !important;
    }

    &__label {
      margin-bottom: 10px;
    }

    &__control {
      input {
        width: 250px;
      }
    }
  }

  .error-text {
    font-size: 14px;
  }
}
