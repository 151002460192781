.user-booking-history {
  background-color: #f7f8f8;

  &__wrapper {
    width: 60%;
    margin: auto;
    padding: 3rem 0;

    .common-return-top-button {
      margin: 0;
      margin-left: auto;
      margin-bottom: 1rem;
      font-weight: 500;
    }
  }

  &__title {
    color: #fc3c60;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__accordion-button.accordion-button {
    padding: 0;
    width: fit-content;
    flex: 1;

    &:not(.collapsed) {
      background-color: #fff;
      box-shadow: unset;
    }

    &:after {
      margin: auto;
    }

    &.is-fetching:after {
      transform: rotate(0) !important;
    }
  }

  &__list-booking {
    overflow: auto;
    margin-bottom: 30px;
    padding: 10px;
  }

  &__shop-image {
    width: 50px;
    height: 50px;
    max-width: 50px;
    min-width: 50px;
    flex: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__booking-item {
    display: flex;
    margin-bottom: 10px;
    background: #fff;
    padding: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .booking-item {
    &__main {
      flex: 2;
      display: flex;
      flex-direction: column;
      margin-right: 10px;
    }

    &__booking-content-button {
      background-color: #efefef;
    }

    &__booking-cancel-button {
      color: #fff;
      background-color: #3f6280;

      span {
        font-size: 10px;
        padding: 3px;
        border-radius: 50%;
        background: #fff;
        margin-right: 10px;
        color: black;
      }

      &:disabled {
        background-color: #9e9e9e;
        cursor: not-allowed;
      }
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      flex: 1;

      button:first-child {
        margin-bottom: 10px;
      }

      button {
        height: 36px;
        border: 0;
        font-size: 12px;
        padding: 10px;
      }
    }

    &__visit-date {
      margin-top: auto;

      p {
        font-size: 12px;
        margin-bottom: 10px;
        line-height: 1.2;
        text-align: center;
      }

      p:nth-last-child(1) {
        padding: 10px;
        width: fit-content;
        background: #f7f8f8;
      }
    }

    &__booking-status {
      display: flex;
      align-items: center;

      .booking-item__status-label {
        margin-bottom: 10px;
      }
    }

    &__status-updated-date {
      font-size: 12px;
      margin-left: auto;
    }

    &__status-label {
      background: #f99cae;
      width: fit-content;
      padding: 10px 15px;
      font-size: 12px;
      border-radius: 20px;
      color: #fff;
    }
  }

  &__list-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 6px;
    background: #fff;
    border-radius: 2px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    > *:not(:nth-last-child(1)) {
      margin-right: 10px;
    }

    &.is-loading {
      margin-bottom: 40px !important;

      &:after {
        content: "読み込み中...";
        position: absolute;
        bottom: -26px;
        left: 8px;
      }
    }
  }

  &__shop-name {
    flex: 9;
  }

  &__list-item:not(:nth-last-of-type(1)) {
    margin-bottom: 1rem;
  }

  .booking-cancel-modal {
    * {
      font-size: 15px;
    }
    .close-button {
      font-size: 1.5rem;
    }
    .dialog {
      margin: auto;
      width: 300px;
      min-width: 300px;

      header {
        font-size: 20px;
        margin-bottom: 1rem;
      }
    }

    button {
      border: 0;
      padding: 8px;
      width: 50px;
      background-color: #3f6280;
      color: #fff;
      display: block;
      font-size: 14px;
      margin: auto;
      border-radius: 2px;

      &:disabled {
        opacity: 0.8;
      }
    }
  }

  .booking-detail-modal {
    * {
      color: #313131;
    }
    .dialog {
      margin: auto;
      width: 500px;
      min-width: 500px;
      padding: 1rem 4rem;

      header {
        font-size: 20px;
      }

      .close-button {
        right: 10px;
        top: 10px;
        font-size: 1.5rem;
      }
    }

    &__label {
      flex: 2;
      justify-content: flex-start;
      font-weight: 500;

      > svg {
        min-width: 20px;
      }
    }

    &__content {
      flex: 6;
      p {
        font-size: 14px;
      }
    }

    &__row {
      display: flex;
      margin-bottom: 1rem;
      align-items: center;
    }

    &__row--menu {
      align-items: flex-start;

      ul {
        li {
          margin-bottom: 6px;
          font-size: 14px;
          line-height: 1.2;
          margin-bottom: 10px;
          // text-indent: -10px;
          // padding-left: 10px;
          line-height: 1.5;
          word-break: break-word;
          white-space: break-spaces;
        }
      }
    }

    &__label {
      min-width: 140px;
      width: 140px;
      display: flex;
      align-items: center;

      > *:not(:nth-last-child(1)) {
        margin-right: 6px;
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .user-booking-history {
    &__wrapper {
      width: 60%;
    }
  }
}
@media only screen and (max-width: 1400px) {
  .user-booking-history {
    &__wrapper {
      width: 70%;
    }
  }
}
@media only screen and (max-width: 900px) {
  .user-booking-history {
    &__wrapper {
      width: 80%;
    }
  }
}
@media only screen and (max-width: 600px) {
  .user-booking-history {
    &__wrapper {
      width: 80%;
    }

    .booking-detail-modal {
      .dialog {
        width: 90%;
        min-width: 90%;
      }
    }

    .booking-item {
      flex-direction: column;

      &__booking-status {
        flex-direction: column;
        justify-content: flex-start;
      }

      &__status-updated-date {
        margin-left: 0;
      }

      &__visit-date {
        margin: 0 auto;
      }

      &__main {
        margin-bottom: 6px;

        > *:not(:nth-last-child(1)) {
          margin-bottom: 5px;
        }
      }

      &__buttons {
        align-items: center;

        > button {
          width: 150px;
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .user-booking-history {
    .booking-detail-modal {
      .dialog {
        padding: 1rem 8vw;
      }
      &__visit-date {
        p {
          margin: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .user-booking-history {
    .booking-detail-modal {
      .dialog {
        padding: 1rem 9vw;
      }

      &__content {
        margin-left: 1rem;
      }

      &__label {
        margin-bottom: 1rem;
      }

      &__row {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
