.user-staffs {
  margin-top: 3rem;
  background-color: #f7f8f8;

  * {
    font-size: 14px;
    color: #595757;
  }

  &__wrapper {
    max-width: 50%;
    margin: auto;
    padding-bottom: 3rem;
  }

  &__note {
    margin-bottom: 15px;
    font-size: 12px;
    color: #fc3c60;
    padding: 0 10px;
    line-height: 1.2;
  }

  &__ttl {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    font-weight: bold;

    > *:not(:nth-last-child(1)) {
      margin-right: 15px;
    }
  }

  &__btn-return-top {
    margin-left: auto;
    display: block;
    padding: 6px 8px;
    background: #fff;
    border: 1px solid #b5b5b6;
    color: #9fa0a0;
    border-radius: 2px;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;

    &.large {
      max-width: 250px;
      margin: auto;
      text-align: center;
      padding: 8px;
    }
  }

  &__list-staff {
    margin-bottom: 5rem;

    li {
      cursor: pointer;
      margin-right: 10px;
    }
  }

  &__staff-name {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }

  &__staff-desc {
    color: #000;
    line-height: 1.2;
    word-break: break-word;
    white-space: break-spaces;
  }

  &__staff-info {
    max-width: 160px;
    display: inline-block;
    margin-bottom: 1.5rem;
    vertical-align: top;

    &.active {
      .user-staffs__staff-name {
        color: #1ebcd9;
      }
    }

    figure {
      width: 160px;
      height: 160px;
      margin-bottom: 14px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.2s ease-out;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .user-staffs {
    &__wrapper {
      max-width: 60%;
    }
  }
}
@media only screen and (max-width: 1400px) {
  .user-staffs {
    &__wrapper {
      max-width: 70%;
    }
  }
}
@media only screen and (max-width: 900px) {
  .user-staffs {
    &__wrapper {
      max-width: 80%;
    }
  }
}

@media only screen and (max-width: 700px) {
  .user-staffs {
    &__staff-info {
      max-width: 150px;

      figure {
        width: 150px;
        height: 150px;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .user-staffs {
    &__btn-return-top.user-menus__btn-return-top {
      white-space: nowrap;
      font-size: 10px;
    }
  }
}

@media only screen and (max-width: 426px) {
  .user-staffs {
    &__list-staff {
      text-align: center;
    }
  }
}

@media only screen and (max-width: 375px) {
  .user-staffs {
    &__wrapper {
      max-width: 100%;
    }
    &__ttl {
      padding: 0 1rem;
    }
  }
}
