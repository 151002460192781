.multi-calendar {
  display: flex;
  width: fit-content;

  > *:not(:nth-last-child(1)) {
    margin-right: 3rem;
  }

  &__calendar {
    display: flex;
    flex-direction: column;

    > *:not(:nth-last-child(1)) {
      margin-bottom: 6px;
    }
  }

  &__header {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }

  &__days-row,
  &__week {
    display: flex;

    > :not(:nth-last-child(1)) {
      margin-right: 6px;
    }
  }

  &__week:not(:nth-last-child(1)) {
    margin-bottom: 6px;
  }

  &__day {
    color: rgba(0, 0, 0, 0.5);
  }

  &__date,
  &__day {
    min-width: 50px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }

  &__date {
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    cursor: pointer;
    background-color: #fff;
  }

  &__date--current-date,
  &__date--current-date.multi-calendar__date--chosen-date {
    border: 2px solid #ff93a9;
  }

  &__date--past-date,
  &__date:disabled {
    color: #8793a1;
    background-color: #f7f7f7;
    border: 0;
    border-radius: 0;
    cursor: not-allowed;
  }

  &__date--chosen-date {
    border: 2px solid #49abbd;
    border-radius: 5px;
  }
}
