$white: #fff;
$gainsboro: #e6e6e6;
$night-rider: #333;
$dark-gray: #b3b3b3;

.ReactModal__Overlay {
  background-color: rgba(255, 255, 255, 0) !important;
  z-index: 99;
}

#create-booking-dialog {
  @media only screen and (max-width: 500px) {
    .dialog header {
      font-size: 1.8rem;
      margin-bottom: 3rem;
    }
    .dialog {
      padding: 1rem 1.5rem;
    }
    .booking-form__btns {
      > :not(:nth-last-child(1)) {
        margin-right: 10px;
      }
    }
  }
}

.create-modal {
  position: absolute;
  top: 8%;
  left: calc(50% - 20%);
  outline: none;
  background-color: $white;
  width: 40%;
  padding: 1rem;
  border: 1px solid $dark-gray;
  border-radius: 10px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  z-index: 999;

  > header {
    user-select: none;

    > h2 {
      text-align: center;
      line-height: 1.2;
      font-weight: bold;
    }
  }

  &__buttons {
    padding: 0.6rem 1rem;
    border-radius: 3px;
    color: #fff;

    &:hover {
      color: #fff;
    }

    &.save {
      background-image: linear-gradient(0, #5d5dff, #5d5dff);

      &:hover {
        background-image: linear-gradient(0, #2e2e2ee3, #5d5dff);
      }
    }

    &.cancel {
      background-image: linear-gradient(0, #6c757d, #6c757d);
      margin-left: auto;

      &:hover {
        background-image: linear-gradient(0, rgba(0, 0, 0, 0.726), #6c757d);
      }
    }
  }
}

.menu-container {
  font-size: 12px;
  max-height: 460px;
  overflow: auto;
  background: #e9e9e9;
  border-radius: 6px;
  padding: 6px 8px;
}

.custom-style-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.custom-style-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}

.custom-style-scrollbar::-webkit-scrollbar-thumb {
  background-color: #8e8e8e;
  border-radius: 10px;
}

.outline-none {
  &:focus {
    box-shadow: unset !important;
  }
}

.rdt {
  display: inline-block;

  > input {
    display: inline-block;
    width: auto;
  }
}

.rdt-outline-none {
  input:focus {
    box-shadow: unset !important;
  }
}

.resize-none {
  resize: none;
}

.modal-close-btn {
  position: absolute;
  right: 14px;
  top: 14px;
  width: 20px;
  height: 20px;
  opacity: 0.3;
  cursor: pointer;
}
.modal-close-btn:hover {
  opacity: 1;
}
.modal-close-btn:before,
.modal-close-btn:after {
  position: absolute;
  left: 9px;
  content: " ";
  height: 20px;
  width: 2px;
  background-color: #333;
}
.modal-close-btn:before {
  transform: rotate(45deg);
}
.modal-close-btn:after {
  transform: rotate(-45deg);
}

.date-time-wrapper {
  display: flex;
  align-items: center;

  > *:not(:nth-last-child(1)) {
    margin-right: 20px;
  }

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;

    > *:not(:nth-last-child(1)) {
      margin-bottom: 5px;
    }
  }
}

@keyframes modal-appear {
  0% {
    top: 8%;
    opacity: 0.9;
  }

  50% {
    top: 9%;
    visibility: visible;
    opacity: 0.95;
  }

  100% {
    top: 10%;
    visibility: visible;
    opacity: 1;
  }
}

.inputGroup {
  // background-color: #fff;
  display: block;
  // margin: 10px 0;
  position: relative;

  label {
    padding: 8px 0;
    padding-left: 30px;
    width: 100%;
    display: block;
    text-align: left;
    color: #3c454c;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 100ms ease-in;
    overflow: hidden;
    font-size: 17px;
    word-break: break-word;
    white-space: break-spaces;

    &:after {
      left: 0;
      width: 20px;
      height: 20px;
      content: "";
      border: 2px solid #d1d7dc;
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: 2px 3px;
      background-size: contain;
      border-radius: 50%;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 16px;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 150ms ease-in;
    }
  }

  input:checked ~ label {
    &:after {
      background-color: #3f51b5;
      border-color: #3f51b5;
    }
  }

  input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
}

// codepen formatting
.menu-group {
  font-size: 18px;
  line-height: 36px;
}

code {
  background-color: #fff;
  padding: 0 8px;
}
